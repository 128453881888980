import React from 'react';
import { Grid, GridItem } from "@chakra-ui/react";
import NavbarLogin from '../common/NavbarLogin';
import Footer from '../common/Footer';
import LoginForm from '../forms/LoginForm';

export default function Accueil() {

    return (


        <>
            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr auto'}
                gridTemplateColumns='1fr'
                color='blackAlpha.700'
                fontWeight='bold'
                bgGradient="linear(to-r, teal.500, blue.500)" // Utilisation du vert et bleu avec un gradient
                minHeight="100vh"
                w="100vw"

            >
                {/* Header (fixe avec Navbar) */}
                <GridItem area={'header'}>
                    <NavbarLogin />
                </GridItem>

                {/* Section principale */}
                <GridItem area={'main'}
                    display="flex"
                    alignItems="center"
                    justifyContent="center" >
                    <LoginForm />
                </GridItem>

                {/* Footer */}
                <GridItem area={'footer'}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};
