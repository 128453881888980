import React from 'react';
import { Box, Flex, Table, Tbody, Tr, Td, Heading, Text, Icon } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons'; // Importer l'icône de validation

const ResultsDisplay = ({ data }) => {
    console.log('Données reçues dans ResultsDisplay:', data);

    // Vérification si data est un tableau
    if (!Array.isArray(data) || data.length === 0) {
        return <Text fontSize="lg" color="red.500">Aucune donnée à afficher.</Text>;
    }

    return (
        <Box display={{ base: "none", lg: "none", xl: "block" }} bg="gray.50" p={5} borderRadius="md" boxShadow="md" mt={5}>
            <Flex justifyContent="center" alignItems="center">
                <Heading as="h2" size="lg" color="teal.600" mb={4}>
                    Résultats du tableau
                </Heading>
            </Flex>

            {data.map((table, index) => (
                <Box key={index} mb={8}>
                    <Heading as="h3" size="md" color="teal.500" mb={2}>
                        Tableau {index + 1}
                    </Heading>

                    <Table variant="simple" size="sm" bg="white" borderRadius="md" boxShadow="sm">
                        <Tbody>
                            {table.map((row, rowIndex) => (
                                <Tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => {
                                        // Vérifier si la cellule contient un "SELECTED"
                                        const isSelected = typeof cell === 'string' && cell.includes("SELECTED,");

                                        // Vérifier si la cellule est à gauche d'un "SELECTED"
                                        const isLeftOfSelected =
                                            cellIndex + 1 < row.length && typeof row[cellIndex + 1] === 'string' && row[cellIndex + 1].includes("SELECTED,");

                                        // Vérifier si la cellule contient un nombre
                                        const isNumber = !isNaN(parseFloat(cell)) && cell !== '';

                                        // Vérifier si la cellule est à gauche d'un nombre
                                        const isLeftOfNumber =
                                            cellIndex + 1 < row.length && !isNaN(parseFloat(row[cellIndex + 1])) && row[cellIndex + 1] !== '';

                                        return (
                                            <Td
                                                key={cellIndex}
                                                border="1px solid"
                                                borderColor="gray.300"
                                                px={2}
                                                py={1}
                                                fontSize="11px"
                                                textAlign="left"
                                                // Appliquer le fond vert si la cellule est à gauche d'un "SELECTED" ou d'un nombre
                                                bg={isLeftOfSelected || isLeftOfNumber ? 'green.100' : 'white'}
                                            >
                                                {isSelected ? (
                                                    <Icon as={CheckIcon} color="green.600" />
                                                ) : isNumber ? (
                                                    <Text textAlign="center" borderRadius="md" bg="orange.200" w="40px" py={2} fontWeight="bold" color="black">{cell}</Text>
                                                ) : (
                                                    cell
                                                )}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>

                </Box>
            ))}
        </Box>
    );
};

export default ResultsDisplay;
