import React from "react";
import {
    Box,
    Image,
    Flex,
    Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import LogoAJ from "../../assets/images/AJScanRepasLogo.jpg";

const Navbar = () => {

    return (
        <Box
            id="Navbar"
            bg="white"
            w="100vw"
            px={4}
        >
            <Flex alignItems="center" justifyContent="space-between" py={1}>
                <Link as={RouterLink} to="/" style={{ textDecoration: "none" }}>
                    <Box
                        fontWeight="bold"
                        fontSize="xl"
                        display="flex"
                        alignItems="center"
                    >
                        <Image
                            src={LogoAJ}
                            alt="Logo Aj Services"
                            width={{ base: "100px", md: "200px" }}
                            ml={3}
                            py={1}
                        />
                    </Box>
                </Link>
            </Flex>
        </Box>
    );
};

export default Navbar;
