import React, { useState, useEffect } from 'react';
import { Heading, VStack, Grid, GridItem, IconButton } from "@chakra-ui/react";
import { ChevronUpIcon } from '@chakra-ui/icons';
import ImageUploader from "../common/ImageUploader";
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';

export default function ScanRepas() {
    const [resetFunction, setResetFunction] = useState(null);
    const [showScrollButton, setShowScrollButton] = useState(false);

    const handleReset = (resetFunc) => {
        setResetFunction(() => resetFunc);
    };

    // Gestion de l'affichage du bouton de scroll
    useEffect(() => {
        const handleScroll = () => {
            // Afficher le bouton quand on scrolle plus bas que 300px
            const shouldShow = window.scrollY > 300;
            setShowScrollButton(shouldShow);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Fonction pour remonter en haut de la page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr auto'}
                gridTemplateColumns='1fr'
                color='blackAlpha.700'
                fontWeight='bold'
                bgGradient="linear(to-r, teal.500, blue.500)"
                minHeight="100vh"
                w="100%"
                maxW="100%"
                overflowX="hidden"
            >
                <GridItem area={'header'}>
                    <Navbar onResetScan={resetFunction} />
                </GridItem>

                <GridItem area={'main'}
                    py={{ base: "8", lg: "16" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <VStack
                        spacing={6}
                        bg="white"
                        pb={{ base: '0', lg: "8" }}
                        borderRadius="md"
                        boxShadow="lg"
                        width="95vw"
                        maxWidth={{ base: '95vw', lg: "92vw", xl: "1750px" }}
                    >
                        <Heading
                            as="h1"
                            fontSize={{ base: "2xl", lg: "3xl" }}
                            color="teal.600"
                            textAlign="center"
                            pt={6}
                        >
                            Commandes de repas
                        </Heading>
                        <ImageUploader onReset={handleReset} />
                    </VStack>
                </GridItem>

                <GridItem area={'footer'}>
                    <Footer />
                </GridItem>
            </Grid>

            {/* Bouton de retour en haut de page */}
            {showScrollButton && (
                <IconButton
                    aria-label="Retour en haut de la page"
                    icon={<ChevronUpIcon />}
                    position="fixed"
                    bottom="6"
                    right="6"
                    size="lg"
                    colorScheme="teal"
                    border="1px solid white"
                    boxShadow="lg"
                    onClick={scrollToTop}
                    display={{ base: 'none', lg: 'flex' }}  // Visible uniquement en version desktop
                    opacity="0.8"
                    _hover={{
                        opacity: "1",
                        transform: "translateY(-2px)",
                    }}
                    transition="all 0.1s"
                    zIndex={999}
                />
            )}
        </>
    );
}