import React from 'react';
import {
    Box,
    Flex,
    Container,
    Text,
    useTheme,
    useColorMode,
} from '@chakra-ui/react';

// Footer
const Footer = () => {

    const { colorMode } = useColorMode();
    const theme = useTheme();
    return (
        <Box as="footer"
            w="100vw"
            py={4}
            bg={
                colorMode === "dark"
                    ? theme.colors.dark.color
                    : theme.colors.light.color
            }
            color="green.600"       >
            <Container maxW="7xl">
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    direction={{ base: "column", md: "row" }} >
                    <Text fontSize={{ base: "md", md: "md" }} >© RockYourNet  {new Date().getFullYear()}.</Text>
                    <Text fontSize={{ base: "md", md: "md" }} >Tous droits réservés.</Text>
                </Flex>
            </Container>
        </Box>
    );
};

export default Footer;