import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const isAuthenticated = localStorage.getItem("isAuthenticated"); // Vérifier l'authentification via localStorage

    // Si l'utilisateur est authentifié, afficher la page demandée
    // Sinon, rediriger vers la page de login
    return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
