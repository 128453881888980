import React, { useState, useEffect } from 'react';
import { Box, Button, Flex, Heading, Text, List, ListItem, IconButton, Input, useToast } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useTableCount } from '../../contexts/TableCountContext';

const MealSummary = ({ data, onValidate }) => {
    const [history, setHistory] = useState([]);
    const toast = useToast();
    const { incrementTableCount, decrementTableCount } = useTableCount();

    // Fonction pour résumer les repas par date
    const summarizeMealsByDate = (tableData) => {
        const summaryByDate = {};
        const dates = tableData[0][0].slice(2).filter((_, index) => index % 2 === 0);

        tableData.forEach((table) => {
            for (let rowIndex = 1; rowIndex < table.length; rowIndex++) {
                const row = table[rowIndex];
                const mealType = row[0];
                const mealCategory = row[1];

                for (let i = 2; i < row.length; i += 2) {
                    const dateIndex = Math.floor((i - 2) / 2);
                    const date = dates[dateIndex];
                    const mealName = row[i];
                    const quantityCell = row[i + 1];
                    const quantity = quantityCell === 'SELECTED,' ? 1 : parseFloat(quantityCell);

                    if (!isNaN(quantity) && quantity > 0) {
                        if (!summaryByDate[date]) {
                            summaryByDate[date] = [];
                        }

                        summaryByDate[date].push({
                            meal: `${mealType.toLowerCase()} - ${mealCategory.toLowerCase()} - ${mealName.toLowerCase()}`,
                            quantity,
                        });
                    }
                }
            }
        });

        return summaryByDate;
    };

    const [mealSummaryByDate, setMealSummaryByDate] = useState(summarizeMealsByDate(data));

    useEffect(() => {
        setMealSummaryByDate(summarizeMealsByDate(data));
    }, [data]);

    const handleMealValidation = (meals) => {
        setHistory([...history, meals]);
        onValidate(meals);
        incrementTableCount();

        toast({
            title: 'Ajout réussi',
            description: 'Les repas ont été ajoutés.',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    };

    const handleUndoLastAction = () => {
        if (history.length > 0) {
            const newHistory = [...history];
            const lastEntry = newHistory.pop();

            setHistory(newHistory);
            decrementTableCount();

            const updatedMeals = {};
            Object.keys(lastEntry).forEach((date) => {
                if (!updatedMeals[date]) {
                    updatedMeals[date] = [];
                }
                lastEntry[date].forEach((meal) => {
                    updatedMeals[date].push({
                        meal: meal.meal,
                        quantity: -meal.quantity,
                    });
                });
            });

            onValidate(updatedMeals);

            toast({
                title: 'Annulation réussie',
                description: 'Les repas ont été retirés.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleRemoveMeal = (date, mealIndex) => {
        const updatedSummary = { ...mealSummaryByDate };
        updatedSummary[date].splice(mealIndex, 1);

        if (updatedSummary[date].length === 0) {
            delete updatedSummary[date];
        }

        setMealSummaryByDate(updatedSummary);
    };

    const handleRemoveDate = (date) => {
        const updatedSummary = { ...mealSummaryByDate };
        delete updatedSummary[date];
        setMealSummaryByDate(updatedSummary);
    };

    const handleQuantityChange = (date, mealIndex, newQuantity) => {
        const updatedSummary = { ...mealSummaryByDate };
        const parsedQuantity = parseFloat(newQuantity);
        if (!isNaN(parsedQuantity) && parsedQuantity >= 0) {
            updatedSummary[date][mealIndex].quantity = parsedQuantity;
            setMealSummaryByDate(updatedSummary);
        }
    };

    const getDayOrder = (date) => {
        const dayOrder = {
            'lundi': 1,
            'mardi': 2,
            'mercredi': 3,
            'jeudi': 4,
            'vendredi': 5,
            'samedi': 6,
            'dimanche': 7
        };

        const day = date.split(' ')[0].toLowerCase();
        return dayOrder[day] || 8;
    };

    const sortedDates = Object.keys(mealSummaryByDate).sort((a, b) => {
        return getDayOrder(a) - getDayOrder(b);
    });

    return (
        <Box bg="white" p={4} borderRadius="md" boxShadow="md" mt={5} width="100%" maxWidth={{ base: '90vw', lg: "1000px" }} >
            <Flex justifyContent="center" alignItems="center">
                <Heading as="h2" size="md" color="teal.600" mb={4}>
                    Résumé des plats sélectionnés par date
                </Heading>
            </Flex>

            <List spacing={4}>
                {sortedDates.length === 0 ? (
                    <ListItem>Aucun plat sélectionné</ListItem>
                ) : (
                    sortedDates.map((date, index) => (
                        <Box key={index} mb={4}>
                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                bg="teal.500"
                                h="50px"
                                mt={4} mb={0}
                                px={4}
                                borderRadius="lg"
                            >
                                <Heading as="h3" size="sm" color="white">
                                    {date.toLowerCase()}
                                </Heading>
                                <IconButton
                                    aria-label="Supprimer la date"
                                    icon={<CloseIcon />}
                                    mr={10}
                                    size="sm"
                                    colorScheme="gray"
                                    onClick={() => handleRemoveDate(date)}
                                />
                            </Flex>

                            <List spacing={2}>
                                {mealSummaryByDate[date].map((item, itemIndex) => (
                                    <ListItem
                                        key={itemIndex}
                                        bg={itemIndex % 2 === 0 ? 'white' : 'gray.100'}
                                        py={1}
                                        px={4}
                                        borderRadius="md"
                                    >
                                        <Flex justifyContent="space-between" alignItems="center">
                                            <Text fontSize={{ base: "md", lg: "md" }} color="blue.600">
                                                {item.meal}:{' '}
                                                <Input
                                                    value={item.quantity}
                                                    size="md"
                                                    width="70px"
                                                    fontWeight="bold"
                                                    ml={2}
                                                    onChange={(e) => handleQuantityChange(date, itemIndex, e.target.value)}
                                                    type="number"
                                                    min="0"
                                                />
                                            </Text>
                                            <IconButton
                                                aria-label="Supprimer le plat"
                                                icon={<CloseIcon />}
                                                size="sm"
                                                colorScheme="blue"
                                                onClick={() => handleRemoveMeal(date, itemIndex)}
                                            />
                                        </Flex>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    ))
                )}
            </List>

            <Flex
                justifyContent="center"
                alignItems="center"
                direction={{ base: "column", md: "row" }}
                gap={{ base: "0", md: "10" }}
                mt={{ base: "0", md: "10" }}
                mb={{ base: "0", md: "6" }}
            >
                <Button
                    colorScheme="teal"
                    w="210px"
                    mt={{ base: "6", md: "0" }}
                    onClick={() => handleMealValidation(mealSummaryByDate)}
                >
                    Valider les informations
                </Button>
                <Button
                    colorScheme="red"
                    w="210px"
                    mt={{ base: "4", md: "0" }}
                    mb={{ base: "4", md: "0" }}
                    onClick={handleUndoLastAction}
                    isDisabled={history.length === 0}
                >
                    Annuler la dernière action
                </Button>
            </Flex>
        </Box>
    );
};

export default MealSummary;