import React, { useState, useEffect } from 'react';
import { Box, Flex, Heading, List, ListItem, Text, Badge, Button, useToast } from '@chakra-ui/react';
import { jsPDF } from 'jspdf';
import { useTableCount } from '../../contexts/TableCountContext';
import * as XLSX from 'xlsx';

const TotalMeals = ({ mealsSummary, data }) => {
    const [isCopying, setIsCopying] = useState(false);
    const toast = useToast();
    const { tableCount } = useTableCount();
    const [dishTypeStats, setDishTypeStats] = useState({});

    const parseFrenchDate = (dateStr) => {
        const monthMap = {
            'janvier': '01', 'février': '02', 'mars': '03', 'avril': '04',
            'mai': '05', 'juin': '06', 'juillet': '07', 'août': '08',
            'septembre': '09', 'octobre': '10', 'novembre': '11', 'décembre': '12'
        };

        const [_, jour, mois, annee = new Date().getFullYear()] = dateStr.toLowerCase().split(' ');
        const moisNum = monthMap[mois];
        return new Date(`${annee}-${moisNum}-${jour.padStart(2, '0')}`);
    };

    const getMealInfo = (mealName) => {
        const parts = mealName.toLowerCase().split(' - ');
        return {
            mealType: parts[0] || 'inconnu',
            dishType: parts[1] || 'inconnu'
        };
    };

    const getMealOrder = (data) => {
        const order = new Map();
        let index = 0;

        if (data && data.length > 0) {
            data.forEach(table => {
                table.forEach((row) => {
                    if (row.length >= 2) {
                        const mealType = row[0]?.toLowerCase() || '';
                        const dishType = row[1]?.toLowerCase() || '';
                        const combinedKey = `${mealType} - ${dishType}`;
                        if (!order.has(combinedKey)) {
                            order.set(combinedKey, index++);
                        }
                    }
                });
            });
        }

        return order;
    };

    const calculateDishTypeStats = () => {
        const stats = {};
        const allMealTypes = new Set();

        if (data && data.length > 0) {
            data.forEach(table => {
                table.forEach((row) => {
                    if (row.length >= 2) {
                        const mealType = row[0]?.toLowerCase() || '';
                        const dishType = row[1]?.toLowerCase() || '';
                        if (mealType && dishType) {
                            allMealTypes.add(`${mealType} - ${dishType}`);
                        }
                    }
                });
            });
        }

        Object.keys(mealsSummary).forEach((date) => {
            Object.keys(mealsSummary[date]).forEach((mealName) => {
                const { mealType, dishType } = getMealInfo(mealName);
                allMealTypes.add(`${mealType} - ${dishType}`);
            });
        });

        Object.keys(mealsSummary).forEach((date) => {
            if (!stats[date]) {
                stats[date] = {};
            }

            allMealTypes.forEach((combinedType) => {
                stats[date][combinedType] = 0;
            });

            Object.entries(mealsSummary[date]).forEach(([mealName, quantity]) => {
                const { mealType, dishType } = getMealInfo(mealName);
                const combinedKey = `${mealType} - ${dishType}`;
                stats[date][combinedKey] += quantity;
            });
        });

        setDishTypeStats(stats);
    };

    useEffect(() => {
        calculateDishTypeStats();
    }, [mealsSummary, data]);

    const mergedMealsSummary = {};
    Object.keys(mealsSummary).forEach((date) => {
        if (!mergedMealsSummary[date]) {
            mergedMealsSummary[date] = {};
        }
        Object.entries(mealsSummary[date]).forEach(([mealName, quantity]) => {
            if (mergedMealsSummary[date][mealName]) {
                mergedMealsSummary[date][mealName] += quantity;
            } else {
                mergedMealsSummary[date][mealName] = quantity;
            }
        });
    });

    const sortedDates = Object.keys(mergedMealsSummary).sort((a, b) => {
        const dateA = parseFrenchDate(a);
        const dateB = parseFrenchDate(b);
        return dateA - dateB;
    });

    const totalMealsCount = sortedDates.reduce((total, date) => {
        const mealsForDate = mergedMealsSummary[date];
        const dateTotal = Object.values(mealsForDate).reduce((acc, quantity) => acc + quantity, 0);
        return total + dateTotal;
    }, 0);

    const exportToExcel = () => {
        if (!data || data.length === 0) {
            toast({
                title: "Erreur",
                description: "Aucune donnée à exporter",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            let excelData = JSON.parse(JSON.stringify(data));

            excelData = excelData.map(table => {
                return table.map((row, rowIndex) => {
                    if (rowIndex === 0) return row;

                    for (let i = 2; i < row.length; i += 2) {
                        const mealName = row[i];
                        const date = table[0][i];
                        const mealType = row[0];
                        const dishType = row[1];

                        const fullMealName = `${mealType.toLowerCase()} - ${dishType.toLowerCase()} - ${mealName.toLowerCase()}`;
                        const quantity = mealsSummary[date]?.[fullMealName] || 0;

                        if (i + 1 < row.length) {
                            row[i + 1] = quantity;
                        }
                    }
                    return row;
                });
            });

            const wb = XLSX.utils.book_new();

            const defaultStyle = {
                alignment: { vertical: "center", horizontal: "center", wrapText: true },
                border: {
                    top: { style: "thin" },
                    bottom: { style: "thin" },
                    left: { style: "thin" },
                    right: { style: "thin" }
                },
                font: { name: "Arial", sz: "11" }
            };

            const headerStyle = {
                ...defaultStyle,
                font: { name: "Arial", sz: "11", bold: true, color: { rgb: "FFFFFF" } },
                fill: { patternType: "solid", fgColor: { rgb: "4F81BD" } }
            };

            const typeColumnStyle = {
                ...defaultStyle,
                font: { name: "Arial", sz: "11", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "DCE6F1" } }
            };

            const quantityStyle = {
                ...defaultStyle,
                font: { name: "Arial", sz: "11", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "E2EFDA" } }
            };

            excelData.forEach((table, index) => {
                const ws = XLSX.utils.aoa_to_sheet(table);

                ws['!rows'] = Array(table.length).fill({ hpt: 30 });
                ws['!cols'] = [
                    { wch: 15 },
                    { wch: 15 },
                    ...Array((table[0].length - 2) / 2).fill(null).flatMap(() => [
                        { wch: 30 },
                        { wch: 10 }
                    ])
                ];

                const range = XLSX.utils.decode_range(ws['!ref']);
                for (let R = range.s.r; R <= range.e.r; ++R) {
                    for (let C = range.s.c; C <= range.e.c; ++C) {
                        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                        if (!ws[cellRef]) continue;

                        if (R === 0) {
                            ws[cellRef].s = headerStyle;
                        } else if (C <= 1) {
                            ws[cellRef].s = typeColumnStyle;
                        } else if (C % 2 === 1) {
                            ws[cellRef].s = quantityStyle;
                        } else {
                            ws[cellRef].s = defaultStyle;
                        }
                    }
                }

                XLSX.utils.book_append_sheet(wb, ws, `Tableau ${index + 1}`);
            });

            const summaryData = [
                ['Date', 'Type de Repas', 'Type de Plat', 'Quantité', 'Tableaux'],
                ...Object.entries(dishTypeStats).flatMap(([date, stats]) =>
                    Object.entries(stats).map(([type, count]) => [
                        date,
                        type.split(' - ')[0],
                        type.split(' - ')[1],
                        count,
                        tableCount
                    ])
                )
            ];

            const ws_summary = XLSX.utils.aoa_to_sheet(summaryData);
            XLSX.utils.book_append_sheet(wb, ws_summary, 'Résumé');

            const date = new Date().toISOString().split('T')[0];
            XLSX.writeFile(wb, `Commandes_Repas_${date}.xlsx`);

            toast({
                title: "Succès",
                description: "Le fichier Excel a été généré avec succès",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

        } catch (error) {
            console.error('Erreur lors de l\'export Excel:', error);
            toast({
                title: "Erreur",
                description: "Une erreur est survenue lors de la génération du fichier Excel",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const generateEmailBody = () => {
        let body = "Voici le total des repas commandés :\n\n";
        sortedDates.forEach((date) => {
            body += `Date: ${date}\n`;
            if (dishTypeStats[date]) {
                body += "Statistiques par type de plat :\n";
                Object.entries(dishTypeStats[date])
                    .sort(([typeA], [typeB]) => typeA.localeCompare(typeB))
                    .forEach(([combinedType, count]) => {
                        body += `- ${combinedType}: ${count} plats / ${tableCount} tableaux\n`;
                    });
            }
            body += '\nDétail des plats :\n';
            Object.entries(mergedMealsSummary[date])
                .sort(([mealA], [mealB]) => mealA.localeCompare(mealB))
                .forEach(([meal, quantity]) => {
                    body += `- ${meal}: ${quantity} plat(s)\n`;
                });
            body += '\n';
        });
        body += `\nTotal global des repas commandés : ${totalMealsCount}`;
        return body;
    };

    const copyToClipboard = async () => {
        setIsCopying(true);
        const emailBody = generateEmailBody();

        try {
            await navigator.clipboard.writeText(emailBody);
            toast({
                title: "Succès !",
                description: "Le texte a été copié dans le presse-papiers.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            try {
                const textarea = document.createElement('textarea');
                textarea.value = emailBody;
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
                toast({
                    title: "Succès !",
                    description: "Le texte a été copié dans le presse-papiers.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } catch (fallbackError) {
                toast({
                    title: "Erreur",
                    description: "Le texte n'a pas pu être copié. Veuillez réessayer.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } finally {
            setIsCopying(false);
        }
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        const logoUrl = `${process.env.PUBLIC_URL}/AJScanRepasLogo512.jpg`;
        const image = new Image();
        image.src = logoUrl;

        image.onload = function () {
            const logoWidth = 50;
            const logoHeight = 50;
            doc.addImage(image, 'JPEG', 10, 0, logoWidth, logoHeight);

            doc.setFont("helvetica", "bold");
            doc.setFontSize(18);

            const pageWidth = doc.internal.pageSize.getWidth();
            let yPosition = logoHeight + 0;

            doc.text("Total des repas commandés", pageWidth / 2, yPosition, { align: 'center' });

            yPosition += 15;

            doc.setFont("helvetica", "normal");
            doc.setFontSize(10);

            sortedDates.forEach((date) => {
                doc.setFont("helvetica", "bold");
                doc.text(`${date.toUpperCase()}`, 10, yPosition);
                yPosition += 8;

                if (dishTypeStats[date]) {
                    doc.setFont("helvetica", "italic");
                    doc.text("Statistiques par type de plat :", 10, yPosition);
                    yPosition += 7;

                    Object.entries(dishTypeStats[date])
                        .sort(([typeA], [typeB]) => {
                            const orderA = mealOrder.get(typeA) ?? Number.MAX_VALUE;
                            const orderB = mealOrder.get(typeB) ?? Number.MAX_VALUE;
                            return orderA - orderB;
                        })
                        .forEach(([combinedType, count]) => {
                            doc.text(`- ${combinedType}: ${count} plats / ${tableCount} tableaux`, 15, yPosition);
                            yPosition += 7;
                            if (yPosition > 280) {
                                doc.addPage();
                                yPosition = 10;
                            }
                        });
                }

                doc.setFont("helvetica", "normal");
                Object.entries(mergedMealsSummary[date])
                    .sort(([mealA, _], [mealB, __]) => {
                        const typeA = getMealInfo(mealA).mealType + ' - ' + getMealInfo(mealA).dishType;
                        const typeB = getMealInfo(mealB).mealType + ' - ' + getMealInfo(mealB).dishType;
                        const orderA = mealOrder.get(typeA) ?? Number.MAX_VALUE;
                        const orderB = mealOrder.get(typeB) ?? Number.MAX_VALUE;
                        if (orderA !== orderB) return orderA - orderB;
                        return mealA.localeCompare(mealB);
                    })
                    .forEach(([meal, quantity]) => {
                        doc.text(`- ${meal}: ${quantity} plat(s)`, 10, yPosition);
                        yPosition += 7;
                        if (yPosition > 280) {
                            doc.addPage();
                            yPosition = 10;
                        }
                    });

                yPosition += 5;
            });

            doc.setFont("helvetica", "bold");
            doc.setFontSize(14);
            doc.text(`Nombre total de plats commandés : ${totalMealsCount}`, 10, yPosition + 10);

            doc.save('TotalRepasCommandes.pdf');
        };
    };

    const mealOrder = getMealOrder(data);

    return (
        <Box bg="white" p={5} borderRadius="md" boxShadow="md" mt={5} w="100%" maxWidth={{ base: '90vw', lg: "1000px" }}>
            <Flex justifyContent="center" alignItems="center">
                <Heading as="h2" size="md" color="teal.600" mb={8}>
                    Total des Repas Commandés
                </Heading>
            </Flex>

            <List spacing={2}>
                {sortedDates.length === 0 ? (
                    <ListItem>Aucun repas commandé pour l'instant.</ListItem>
                ) : (
                    sortedDates.map((date, index) => (
                        <Box key={index} mb={12}>
                            <Heading as="h4" size="md" color="teal.500" mb={2}>
                                {date.toLowerCase()}
                            </Heading>

                            {dishTypeStats[date] && (
                                <Box mb={3}>
                                    <Text fontSize="md" fontWeight="fat" color="gray.600" mb={2}>
                                        Statistiques par type de plat :
                                    </Text>
                                    {Object.entries(dishTypeStats[date])
                                        .sort(([typeA], [typeB]) => {
                                            const orderA = mealOrder.get(typeA) ?? Number.MAX_VALUE;
                                            const orderB = mealOrder.get(typeB) ?? Number.MAX_VALUE;
                                            return orderA - orderB;
                                        })
                                        .map(([combinedType, count], idx) => (
                                            <Flex
                                                key={idx}
                                                fontSize="sm"
                                                color="gray.600"
                                                ml={2}
                                                direction={{ base: "column", md: "row" }}
                                                gap={{ base: 2, md: 0 }}
                                                alignItems={{ base: "flex-start", md: "center" }}
                                            >
                                                <Text fontWeight="medium">
                                                    {combinedType}:
                                                </Text>
                                                <Badge
                                                    colorScheme={count !== tableCount ? "orange" : "blue"}
                                                    fontSize="1em"
                                                    ml={{ base: 0, md: 2 }}
                                                >
                                                    {count} plats / {tableCount} tableaux
                                                </Badge>
                                            </Flex>
                                        ))}
                                </Box>
                            )}
                            <Text fontSize="md" fontWeight="fat" color="gray.600" mb={2}>
                                Liste des plats :
                            </Text>
                            <List>
                                {Object.entries(mergedMealsSummary[date])
                                    .sort(([mealA, _], [mealB, __]) => {
                                        const typeA = getMealInfo(mealA).mealType + ' - ' + getMealInfo(mealA).dishType;
                                        const typeB = getMealInfo(mealB).mealType + ' - ' + getMealInfo(mealB).dishType;
                                        const orderA = mealOrder.get(typeA) ?? Number.MAX_VALUE;
                                        const orderB = mealOrder.get(typeB) ?? Number.MAX_VALUE;
                                        if (orderA !== orderB) return orderA - orderB;
                                        return mealA.localeCompare(mealB);
                                    })
                                    .map(([mealName, quantity], idx) => (
                                        <ListItem key={idx}>
                                            <Flex
                                                fontSize={{ base: "md", lg: "sm" }}
                                                fontWeight="medium"
                                                color="blue.600"
                                                direction={{ base: "column", md: "row" }}
                                                gap={{ base: 2, md: 0 }}
                                                alignItems={{ base: "flex-start", md: "center" }}
                                            >
                                                <Text>
                                                    {mealName}:
                                                </Text>
                                                <Flex
                                                    color="teal.600"
                                                    alignItems="center"
                                                    ml={{ base: 0, md: 1 }}
                                                >
                                                    <Badge
                                                        colorScheme="green"
                                                        fontSize="1em"
                                                        mr={1}
                                                    >
                                                        {quantity}
                                                    </Badge>
                                                    plat(s) commandé(s)
                                                </Flex>
                                            </Flex>
                                        </ListItem>
                                    ))}
                            </List>
                        </Box>
                    ))
                )}
            </List>

            <Box mt={8} mb={12}>
                <Text fontSize={{ base: "md", lg: "lg" }} fontWeight="bold" color="green.600">
                    Total global des repas commandés :{' '}
                    <Badge colorScheme="teal" fontSize="1em" py={1} pl={2} pr={3}>
                        {totalMealsCount}
                    </Badge>
                </Text>
            </Box>

            <Flex
                justifyContent="center"
                alignItems="center"
                my={6}
                spacing={4}
                direction={{ base: "column", md: "row" }}
                gap={{ base: "0", md: "10" }}
            >
                <Button
                    colorScheme="blue"
                    onClick={copyToClipboard}
                    isLoading={isCopying}
                    loadingText="Copie..."
                    mb={{ base: 4, md: 0 }}
                >
                    Copier pour email
                </Button>

                <Button
                    colorScheme="teal"
                    onClick={downloadPDF}
                    mb={{ base: 4, md: 0 }}
                >
                    Télécharger en PDF
                </Button>

                <Button
                    colorScheme="green"
                    onClick={exportToExcel}
                >
                    Exporter en Excel
                </Button>
            </Flex>
        </Box>
    );
};

export default TotalMeals;