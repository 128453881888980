import React from 'react';
import { Button, Icon, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaPowerOff } from "react-icons/fa"; // Icône de déconnexion

const LogoutButton = () => {
    const toast = useToast();
    const navigate = useNavigate(); // Utilisation de la navigation pour rediriger l'utilisateur

    // Fonction de déconnexion
    const déconnexion = () => {
        // Suppression des informations d'authentification (exemple avec localStorage)
        localStorage.removeItem('isAuthenticated'); // Suppression du flag d'authentification
        localStorage.removeItem('authToken'); // Suppression éventuelle du token si utilisé

        // Afficher une notification de succès
        toast({
            title: 'Déconnexion réussie.',
            description: "Vous avez été déconnecté avec succès.",
            status: 'success',
            duration: 5000,
            isClosable: true,
        });

        // Redirection vers la page de connexion après la déconnexion
        navigate('/login'); // Rediriger vers la page de connexion
    };

    return (
        <Button
            onClick={déconnexion}  // Appel de la fonction déconnexion
            colorScheme="green"     // Couleur du bouton
            mr={4}
            aria-label="déconnexion"
        >
            <Icon as={FaPowerOff} /> {/* Icône de déconnexion */}
        </Button>
    );
};

export default LogoutButton;
