import React, { createContext, useContext, useState } from 'react';

const TableCountContext = createContext();

export const TableCountProvider = ({ children }) => {
    const [tableCount, setTableCount] = useState(0);

    const incrementTableCount = () => {
        setTableCount(prev => prev + 1);
    };

    const decrementTableCount = () => {
        setTableCount(prev => Math.max(0, prev - 1));
    };

    const resetTableCount = () => {
        setTableCount(0);
    };

    return (
        <TableCountContext.Provider value={{
            tableCount,
            incrementTableCount,
            decrementTableCount,
            resetTableCount
        }}>
            {children}
        </TableCountContext.Provider>
    );
};

export const useTableCount = () => useContext(TableCountContext);